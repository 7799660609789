import { memo, useEffect, useState, useCallback } from 'react'
import Layout from 'layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import ReportSkeleton from './components/skeleton/report_skeleton'
import CarriersTableSkeleton from './components/skeleton/carriers_table_skeleton'
import { AggregateColumnDirective, Aggregate, ColumnDirective, ColumnsDirective, GridComponent, Inject, Freeze, Sort } from '@syncfusion/ej2-react-grids'
import { AggregateColumnsDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids'
import 'bootstrap-daterangepicker/daterangepicker.css'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import StatCounter from 'statcounter'
import moment from 'moment-timezone'
moment.tz.setDefault("America/Chicago")

const Analytics = (props) => {

	const { carriers_data, web_sales_data, getCarriersAnalyticsData } = props

	let gridInstance;
	let gridInstance2;
	const [isPageLoading, setIsPageLoading] = useState(true)
	const [isDataLoading, setIsDataLoading] = useState(true)
	const [startDate, setStartDate] = useState(moment().subtract('7', 'days').format('MM/DD/YYYY'))
	const [endDate, setEndDate] = useState(moment().subtract('1', 'day').format('MM/DD/YYYY'))
	// const [page, setPage] = useState(1)
	const [gridKey, setGridKey] = useState(Date.now())
	
	const fetchCarriersData = useCallback(() => {
		setIsDataLoading(true)

		const from_date = moment(startDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
		const to_date = moment(endDate, 'MM/DD/YYYY').format('YYYY-MM-DD')

		getCarriersAnalyticsData({ from_date, to_date }).then(response => {
			setIsPageLoading(false)
			setIsDataLoading(false)
			if(response.data.success) {
				setGridKey(Date.now())
			}

		}).catch(err => setIsPageLoading(false))

	}, [getCarriersAnalyticsData, startDate, endDate])

	useEffect(() => {
		if(gridInstance) {
			gridInstance.dataSource = carriers_data
		}
	}, [gridInstance, carriers_data, gridKey])

	useEffect(() => {
		if(gridInstance2) {
			gridInstance2.dataSource = web_sales_data
		}
	}, [gridInstance2, web_sales_data, gridKey])

	useEffect(() => {
		fetchCarriersData()
	}, [fetchCarriersData])

	const handleDateChange = (event, picker) => {
		setStartDate(picker.startDate.format('MM/DD/YYYY'))
		setEndDate(picker.endDate.format('MM/DD/YYYY'))
		// setPage(1)
	}

	const footerSum = props => <span>{props.Sum}</span>
	const footerRatio = props => <span>{ Number(props.Custom).toLocaleString('en-US', {style: 'percent', 'minimumFractionDigits': 2}) }</span>
	const footerCpa = props => <span>{ Number(props.Custom).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) }</span>
	const footerCvr = props => <span>{ Number(props.Custom).toLocaleString('en-US', {style: 'percent', 'minimumFractionDigits': 2}) }</span>
	const footerCurrency = props => <span>{ Number(props.Sum).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) }</span>

	const showSpend = item => Number(item.spend).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
	const showAmount = item => Number(item.total_amount).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
	const showCpa = item => Number(item.web_sales_cpa).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
	const showCpc = item => Number(item.cpc).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
	const showCvr = item =>  Number(item.web_sales_cvr/100).toLocaleString('en-US', {style: 'percent', minimumFractionDigits: 2})
	const showWebSalesRate = item =>  Number(item.web_sales_rate/100).toLocaleString('en-US', {style: 'percent', minimumFractionDigits: 2})
	const showArbSalesRate = item =>  Number(item.arb_sales_rate/100).toLocaleString('en-US', {style: 'percent', minimumFractionDigits: 2})
	const showNewWebSalesRate = item =>  Number(item.new_web_sales_rate/100).toLocaleString('en-US', {style: 'percent', minimumFractionDigits: 2})
	const showNewWebRecurringWebRate = item =>  Number(item.new_web_recurring_web_rate/100).toLocaleString('en-US', {style: 'percent', minimumFractionDigits: 2})
	const showRecurringWebSalesRate = item =>  Number(item.recurring_web_sales_rate/100).toLocaleString('en-US', {style: 'percent', minimumFractionDigits: 2})
	const showRoas = item =>  Number(item.roas/100).toLocaleString('en-US', {style: 'percent', minimumFractionDigits: 2})

	const totalWebSalesRateFn = (args) => {
		let web_sales = (args.result.map(item => item.web_sales)).reduce((partialSum, a) => partialSum + a, 0)
		let total_sales = (args.result.map(item => item.total_sales)).reduce((partialSum, a) => partialSum + a, 0)

		if(total_sales > 0) {
			return (web_sales/total_sales)
		}

		return 0
	}

	const totalArbSalesRateFn = (args) => {
		let arb_sales = (args.result.map(item => item.arb_sales)).reduce((partialSum, a) => partialSum + a, 0)
		let total_sales = (args.result.map(item => item.total_sales)).reduce((partialSum, a) => partialSum + a, 0)

		if(total_sales > 0) {
			return (arb_sales/total_sales)
		}

		return 0
	}

	const totalNewWebSalesRateFn = (args) => {
		let new_web_sales = (args.result.map(item => item.new_web_sales)).reduce((partialSum, a) => partialSum + a, 0)
		let total_sales = (args.result.map(item => item.total_sales)).reduce((partialSum, a) => partialSum + a, 0)

		if(total_sales > 0) {
			return (new_web_sales/total_sales)
		}

		return 0
	}

	const totalNewWebRecurringWebRateFn = (args) => {
		let new_web_sales = (args.result.map(item => item.new_web_sales)).reduce((partialSum, a) => partialSum + a, 0)
		let recurring_web_sales = (args.result.map(item => item.recurring_web_sales)).reduce((partialSum, a) => partialSum + a, 0)

		if(recurring_web_sales > 0) {
			return (new_web_sales/recurring_web_sales)
		}

		return 0
	}

	const totalRecurringWebSalesRateFn = (args) => {
		let recurring_web_sales = (args.result.map(item => item.recurring_web_sales)).reduce((partialSum, a) => partialSum + a, 0)
		let total_sales = (args.result.map(item => item.total_sales)).reduce((partialSum, a) => partialSum + a, 0)

		if(total_sales > 0) {
			return (recurring_web_sales/total_sales)
		}

		return 0
	}

	const totalCpaFn = (args) => {
		let total_spend = (args.result.map(item => item.spend)).reduce((partialSum, a) => partialSum + a, 0)
		let total_sales = (args.result.map(item => item.web_sales)).reduce((partialSum, a) => partialSum + a, 0)

		if(total_sales > 0) {
			return (total_spend/total_sales)
		}

		return 0
	}

	const totalCvrFn = (args) => {
		let total_clicks = (args.result.map(item => item.clicks)).reduce((partialSum, a) => partialSum + a, 0)
		let total_sales = (args.result.map(item => item.web_sales)).reduce((partialSum, a) => partialSum + a, 0)

		if(total_clicks > 0) {
			return (total_sales/total_clicks)
		}

		return 0
	}

	const totalCpcFn = (args) => {
		let total_spend = (args.result.map(item => item.spend)).reduce((partialSum, a) => partialSum + a, 0)
		let total_clicks = (args.result.map(item => item.clicks)).reduce((partialSum, a) => partialSum + a, 0)

		if(total_clicks > 0) {
			return (total_spend/total_clicks)
		}

		return 0
	}

	const totalRoasFn = (args) => {
		let total_amount = (args.result.map(item => item.total_amount)).reduce((partialSum, a) => partialSum + a, 0)
		let total_spend = (args.result.map(item => item.spend)).reduce((partialSum, a) => partialSum + a, 0)

		if(total_spend > 0) {
			return (total_amount/total_spend)
		}

		return 0
	}

	return (
		<>
			<Layout>
				<Row className="mb-3">
					<Col md={3}>
						<Form.Group>
							<DateRangePicker onApply={handleDateChange} initialSettings={{ startDate: startDate, endDate: endDate, ranges: {
					            Today: [moment().toDate(), moment().toDate()],
					            Yesterday: [
					              moment().subtract(1, 'days').toDate(),
					              moment().subtract(1, 'days').toDate(),
					            ],
					            'Last 7 Days': [
					              moment().subtract(7, 'days').toDate(),
					              moment().subtract(1, 'day').toDate(),
					            ],
					            'Last 30 Days': [
					              moment().subtract(30, 'days').toDate(),
					              moment().subtract(1, 'day').toDate(),
					            ],
					            'This Month': [
					              moment().startOf('month').toDate(),
					              moment().endOf('month').toDate(),
					            ],
					            'Last Month': [
					              moment().subtract(1, 'month').startOf('month').toDate(),
					              moment().subtract(1, 'month').endOf('month').toDate(),
					            ],
				          	}}}>
								<input type="text" className="form-control" />
							</DateRangePicker>
						</Form.Group>
					</Col>
				</Row>

				{!isPageLoading &&
					<>
						<Row className="mb-3">
							<Col md={12}>
								<Card>
									<Card.Header>Sales Type Summary</Card.Header>

									<Card.Body>
										{!isDataLoading &&
											
											<Row>
												<Col md={12}>
													<GridComponent ref={grid => gridInstance = grid} enableAdaptiveUI={true} allowSorting={true} frozenColumns={1} loadingIndicator={{ indicatorType: 'Shimmer' }}>
														<ColumnsDirective>
															<ColumnDirective field='carrier_name' width='150' headerText='Carrier' />
															<ColumnDirective field='total_sales' width='120' headerText='Total Sales' />
															<ColumnDirective field='web_sales' width='150' headerText='Web Sales' />
															<ColumnDirective field='arb_sales' width='120' headerText='ARB Sales' />
															<ColumnDirective field='other_sales' width='120' headerText='Other Sales' />
															<ColumnDirective field='new_web_sales' width='150' headerText='New Web Sales' />
															<ColumnDirective field='recurring_web_sales' width='180' headerText='Recurring Web Sales' />
															<ColumnDirective field='new_arb_sales' width='150' headerText='New ARB Sales' />
															<ColumnDirective field='recurring_arb_sales' width='180' headerText='Recurring ARB Sales' />
															<ColumnDirective field='web_sales_rate' width='120' headerText='Web > Total' template={showWebSalesRate} />
															<ColumnDirective field='arb_sales_rate' width='120' headerText='ARB > Total' template={showArbSalesRate} />
															<ColumnDirective field='new_web_sales_rate' width='180' headerText='New Web > Total' template={showNewWebSalesRate} />
															<ColumnDirective field='new_web_recurring_web_rate' width='200' headerText='New Web > Return Web' template={showNewWebRecurringWebRate} />
															<ColumnDirective field='recurring_web_sales_rate' width='180' headerText='Return Web > Total' template={showRecurringWebSalesRate} />
														</ColumnsDirective>
														<Inject services={[Freeze, Sort, Aggregate]}/>

														<AggregatesDirective>
															<AggregateDirective>
																<AggregateColumnsDirective>
																	<AggregateColumnDirective field='carrier_name' width='120' type='Custom' footerTemplate={() => <span>Total</span>} />
																	<AggregateColumnDirective field='total_sales' width='120' type='Sum' footerTemplate={footerSum} />
																	<AggregateColumnDirective field='web_sales' width='120' type='Sum' footerTemplate={footerSum} />
																	<AggregateColumnDirective field='arb_sales' width='120' type='Sum' footerTemplate={footerSum} />
																	<AggregateColumnDirective field='other_sales' width='120' type='Sum' footerTemplate={footerSum} />
																	<AggregateColumnDirective field='new_web_sales' width='120' type='Sum' footerTemplate={footerSum} />
																	<AggregateColumnDirective field='recurring_web_sales' width='120' type='Sum' footerTemplate={footerSum} />
																	<AggregateColumnDirective field='new_arb_sales' width='120' type='Sum' footerTemplate={footerSum} />
																	<AggregateColumnDirective field='recurring_arb_sales' width='120' type='Sum' footerTemplate={footerSum} />
																	<AggregateColumnDirective field='web_sales_rate' width='120' type='Custom' customAggregate={totalWebSalesRateFn} footerTemplate={footerRatio} />
																	<AggregateColumnDirective field='arb_sales_rate' width='120' type='Custom' customAggregate={totalArbSalesRateFn} footerTemplate={footerRatio} />
																	<AggregateColumnDirective field='new_web_sales_rate' width='120' type='Custom' customAggregate={totalNewWebSalesRateFn} footerTemplate={footerRatio} />
																	<AggregateColumnDirective field='new_web_recurring_web_rate' width='120' type='Custom' customAggregate={totalNewWebRecurringWebRateFn} footerTemplate={footerRatio} />
																	<AggregateColumnDirective field='recurring_web_sales_rate' width='120' type='Custom' customAggregate={totalRecurringWebSalesRateFn} footerTemplate={footerRatio} />
																</AggregateColumnsDirective>
															</AggregateDirective>
														</AggregatesDirective>								
													</GridComponent>
												</Col>
											</Row>
										}
										{isDataLoading &&
											<CarriersTableSkeleton />
										}
									</Card.Body>
								</Card>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col md={12}>
								<Card>
									<Card.Header>Web Sales Summary</Card.Header>

									<Card.Body>
										{!isDataLoading &&
											
											<Row>
												<Col md={12}>
													<GridComponent ref={grid => gridInstance2 = grid} enableAdaptiveUI={true} allowSorting={true} frozenColumns={1} loadingIndicator={{ indicatorType: 'Shimmer' }}>
														<ColumnsDirective>
															<ColumnDirective field='carrier_name' width='150' headerText='Carrier' />
															<ColumnDirective field='total_sales' width='120' headerText='Total Sales' />
															<ColumnDirective field='web_sales' width='150' headerText='Web Sales' />
															<ColumnDirective field='total_amount' width='180' headerText='Web Refill Amount' template={showAmount} />
															<ColumnDirective field='web_sales_cpa' width='150' headerText='Web CPA' template={showCpa} />
															<ColumnDirective field='web_sales_cvr' width='150' headerText='Web Conv. Rate' template={showCvr} />
															<ColumnDirective field='impressions' width='150' headerText='Impressions' />
															<ColumnDirective field='clicks' width='120' headerText='Clicks' />
															<ColumnDirective field='spend' width='120' headerText='Cost' template={showSpend} />
															<ColumnDirective field='cpc' width='120' headerText='Avg. CPC' template={showCpc} />
															<ColumnDirective field='new_web_sales' width='150' headerText='New Web Sales' />
															<ColumnDirective field='new_web_sales_rate' width='180' headerText='New Web > Total' template={showNewWebSalesRate} />
															<ColumnDirective field='roas' width='120' headerText='ROAS' template={showRoas} />

														</ColumnsDirective>
														<Inject services={[Freeze, Sort, Aggregate]}/>

														<AggregatesDirective>
															<AggregateDirective>
																<AggregateColumnsDirective>
																	<AggregateColumnDirective field='carrier_name' width='120' type='Custom' footerTemplate={() => <span>Total</span>} />
																	<AggregateColumnDirective field='total_sales' width='120' type='Sum' footerTemplate={footerSum} />
																	<AggregateColumnDirective field='web_sales' width='120' type='Sum' footerTemplate={footerSum} />
																	<AggregateColumnDirective field='total_amount' width='180' type='Sum' footerTemplate={footerCurrency} />
																	<AggregateColumnDirective field='web_sales_cpa' width='120' type='Custom' customAggregate={totalCpaFn} footerTemplate={footerCpa} />
																	<AggregateColumnDirective field='web_sales_cvr' width='120' type='Custom' customAggregate={totalCvrFn} footerTemplate={footerCvr} />
																	<AggregateColumnDirective field='impressions' width='150' type='Sum' footerTemplate={footerSum} />
																	<AggregateColumnDirective field='clicks' width='120' type='Sum' footerTemplate={footerSum} />
																	<AggregateColumnDirective field='spend' width='120' type='Sum' footerTemplate={footerCurrency} />
																	<AggregateColumnDirective field='cpc' width='120' type='Custom' customAggregate={totalCpcFn} footerTemplate={footerCpa} />
																	<AggregateColumnDirective field='new_web_sales' width='120' type='Sum' footerTemplate={footerSum} />
																	<AggregateColumnDirective field='new_web_sales_rate' width='120' type='Custom' customAggregate={totalNewWebSalesRateFn} footerTemplate={footerRatio} />
																	<AggregateColumnDirective field='roas' width='120' type='Custom' customAggregate={totalRoasFn} footerTemplate={footerRatio} />
																</AggregateColumnsDirective>
															</AggregateDirective>
														</AggregatesDirective>								
													</GridComponent>
												</Col>
											</Row>
										}
										{isDataLoading &&
											<CarriersTableSkeleton />
										}
									</Card.Body>
								</Card>
							</Col>
						</Row>


					</>
				}

				{isPageLoading &&
					<ReportSkeleton />
				}

				<StatCounter sc_project={12578404} sc_security="784cb42e" sc_image_url="https://c.statcounter.com/12578404/0/784cb42e/1/" />
			</Layout>
		</>
	)
}

export default memo(Analytics)