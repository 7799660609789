import { memo, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Link } from "react-router-dom"
import { useAuth } from "context/AuthProvider"
import { useNavigate, useLocation } from "react-router-dom"

const Header = (props) => {

	const { authenticated, user, fetchUser, submitLogout } = props
 	const navigate = useNavigate()
 	const location = useLocation()
	const { logout } = useAuth()

	useEffect(() => {
    if(authenticated && !Object.keys(user).length) {
      fetchUser()
    }
  }, [authenticated, fetchUser, user])

	const logoutUser = () => {
		submitLogout().then(response => {
			logout()
			navigate("/login")
		})	
	}

	return (
		<>
			<Navbar expand="lg" className="bg-light" sticky="top">
				<Container>
					<Navbar.Brand href="/">Cellpay</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />

					{authenticated &&
						<>
							<Navbar.Collapse id="navbarScroll">
								<Nav className="me-auto my-2 my-lg-0" navbarScroll>
									<Nav.Link as={Link} active={location.pathname === '/dashboard'} to="/dashboard">Dashboard</Nav.Link>
									<NavDropdown title="Carriers">
										<NavDropdown.Item href="/carriers/orders" target="_blank">Orders</NavDropdown.Item>
										<NavDropdown.Item href="/carriers/multiorders" target="_blank">Multi-Orders</NavDropdown.Item>
										<NavDropdown.Item as={Link} active={location.pathname === '/carriers/report'} to="/carriers/report">Report</NavDropdown.Item>
										<NavDropdown.Item as={Link} active={location.pathname === '/carriers/analytics'} to="/carriers/analytics">Analytics</NavDropdown.Item>
									</NavDropdown>
								</Nav>

								<Nav className="d-flex">
									<NavDropdown title={`${user.first_name} ${user.last_name}`}>
										<NavDropdown.Item as={Link}  onClick={logoutUser}>Logout</NavDropdown.Item>
									</NavDropdown>
								</Nav>
							</Navbar.Collapse>

						</>
					}


				</Container>
			</Navbar>
		</>
	)
}

export default memo(Header)