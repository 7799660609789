const initialState = {
	carriers_data: {},
	web_sales_data: {},
	total_account_data: {'sales': 0, refill_amount: 0, clicks: 0, impressions: 0, spend: 0},
}

const analyticsReducer = (state=initialState, action) => {
	switch(action.type) {
		case 'UPDATE_ANALYTICS_DATA':
			return {
				...state,
				carriers_data: [...action.payload.carriers_data],
				web_sales_data: [...action.payload.web_sales_data],
				// total_account_data: {...state.total_account_data, ...action.payload.total_account_data},
			}

		default:
			return { ...state }

	}
}

export default analyticsReducer