import { memo, useEffect, useState, useCallback } from 'react'
import Layout from 'layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import DashboardSkeleton from './components/skeleton/dashboard_skeleton'
import { AggregateColumnDirective, Aggregate, ColumnDirective, ColumnsDirective, GridComponent, Inject, Freeze, Sort } from '@syncfusion/ej2-react-grids'
import { AggregateColumnsDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids'
import { TreeGridComponent } from '@syncfusion/ej2-react-treegrid'
import { Inject as TreeGridInject, Sort as TreeGridSort } from '@syncfusion/ej2-react-treegrid'
import StatCounter from 'statcounter'

const Dashboard = (props) => {

	let gridInstance;
	const { carriers_data, past_carriers_data, total_account_data, getDashboardData } = props
	const [isPageLoading, setIsPageLoading] = useState(true)
	// const [page, setPage] = useState(1)
	// const [totalRows, setTotalRows] = useState(0)
	// const [sortBy, setSortBy] = useState(null)
	// const [sortDirection, setSortDirection] = useState(null)
	const [gridKey, setGridKey] = useState(Date.now())

	const fetchDashboardData = useCallback(() => {

		getDashboardData().then(response => {
			setIsPageLoading(false)
			if(response.data.success) {
				setGridKey(Date.now())
			}

		}).catch(err => setIsPageLoading(false))
	}, [getDashboardData])

	useEffect(() => {
		if(gridInstance) {
			gridInstance.dataSource = carriers_data
		}
	}, [gridInstance, carriers_data, gridKey])

	useEffect(() => {
		fetchDashboardData()
	}, [fetchDashboardData])

	/*const dataStateChange = (args) => {
		if(args.action.requestType === 'sorting') {
			if(args.action.columnName && args.action.direction) {
				setSortBy(args.action.columnName)
				setSortDirection(args.action.direction)
			} else {
				setSortBy(null)
				setSortDirection(null)
			}

			return
		}
	}*/



	/*const getChartOptions = () => {

		let dataPoints = sales_chart.map(item => {
			return {'label': item.label, 'y': item.sales_ratio  }
		})

		return {

			animationEnabled: false,
			exportEnabled: false,
			title:{
				text: "Carriers Sales"
			},
			data: [{
				type: "pie",
				indexLabel: "{label}: {y}%",
				startAngle: -90,
				dataPoints: dataPoints,
			}]
		}
	}*/

	const showAmount = item => Number(item.total_amount).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
	const showSpend = item => Number(item.spend).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
	const showCpa = item => Number(item.cpa).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
	const showCvr = item =>  Number(item.cvr/100).toLocaleString('en-US', {style: 'percent', minimumFractionDigits: 2})
	const showRoas = item =>  Number(item.roas/100).toLocaleString('en-US', {style: 'percent', minimumFractionDigits: 2})
	const footerSum = props => <span>{props.Sum}</span>
	const footerCurrency = props => <span>{ Number(props.Sum).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) }</span>
	const footerCpa = props => <span>{ Number(props.Custom).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) }</span>
	const footerCvr = props => <span>{ Number(props.Custom).toLocaleString('en-US', {style: 'percent', 'minimumFractionDigits': 2}) }</span>
	const footerRoas = props => <span>{ Number(props.Custom).toLocaleString('en-US', {style: 'percent', 'minimumFractionDigits': 2}) }</span>
	const totalCpaFn = (args) => {
		let total_spend = (args.result.map(item => item.spend)).reduce((partialSum, a) => partialSum + a, 0)
		let total_sales = (args.result.map(item => item.sales)).reduce((partialSum, a) => partialSum + a, 0)
		
		if(total_sales > 0) {
			return (total_spend/total_sales)
		}

		return 0
	}

	const totalCvrFn = (args) => {
		let total_clicks = (args.result.map(item => item.clicks)).reduce((partialSum, a) => partialSum + a, 0)
		let total_sales = (args.result.map(item => item.sales)).reduce((partialSum, a) => partialSum + a, 0)

		if(total_clicks > 0) {
			return (total_sales/total_clicks)
		}

		return 0
	}

	const totalRoasFn = (args) => {
		let total_amount = (args.result.map(item => item.total_amount)).reduce((partialSum, a) => partialSum + a, 0)
		let total_spend = (args.result.map(item => item.spend)).reduce((partialSum, a) => partialSum + a, 0)

		if(total_spend > 0) {
			return (total_amount/total_spend)
		}

		return 0
	}

	// const accountTotalSales = props => <span>{props.Sum}</span>
	// const accountTotalRefill = props => <span>{ Number(total_account_data.refill_amount).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) }</span>
	const accountTotalClicks = props => <span>{ total_account_data.clicks }</span>
	const accountTotalSpend = props => <span>{ Number(total_account_data.spend).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) }</span>
	const accountTotalCpaFn = (args) => {
		let total_spend = total_account_data.spend
		let total_sales = (args.result.map(item => item.sales)).reduce((partialSum, a) => partialSum + a, 0)
		
		if(total_sales > 0) {
			return (total_spend/total_sales)
		}

		return 0
	}

	const accountTotalCvrFn = (args) => {
		let total_clicks = total_account_data.clicks
		let total_sales = (args.result.map(item => item.sales)).reduce((partialSum, a) => partialSum + a, 0)

		if(total_clicks > 0) {
			return (total_sales/total_clicks)
		}

		return 0
	}

	const accountTotalRoasFn = (args) => {
		let total_amount = (args.result.map(item => item.total_amount)).reduce((partialSum, a) => partialSum + a, 0)
		let total_spend = total_account_data.spend

		if(total_spend > 0) {
			return (total_amount/total_spend)
		}

		return 0
	}

	return (
		<>
			<Layout>
				{!isPageLoading &&
					<>
						{/*{sales_chart.length > 0 &&
							<Row className="mb-2">
								<Col md={12}>
									<CanvasJSChart options = { getChartOptions() } />
								</Col>	
							</Row>
						}*/}

						<Row className="mb-3">
							<Col md={12}>
								<Card>
									<Card.Header>Todays Metrics</Card.Header>

									<Card.Body>
										<Row className="mb-3">
											<Col md={12}>
												<GridComponent ref={grid => gridInstance = grid} enableAdaptiveUI={true} allowSorting={true} frozenColumns={1} loadingIndicator={{ indicatorType: 'Shimmer' }}>
													<ColumnsDirective>
														<ColumnDirective field='carrier_name' width='120' headerText='Carrier' />
														<ColumnDirective field='sales' width='120' headerText='Sales' />
														<ColumnDirective field='total_amount' width='150' headerText='Refill Amount' template={showAmount} />
														<ColumnDirective field='clicks' width='120' headerText='Clicks' />
														<ColumnDirective field='spend' width='120' headerText='Cost' template={showSpend} />
														<ColumnDirective field='cpa' width='120' headerText='CPA' template={showCpa} />
														<ColumnDirective field='cvr' width='120' headerText='Conv. Rate' template={showCvr} />
														<ColumnDirective field='roas' width='120' headerText='ROAS' template={showRoas} />
													</ColumnsDirective>

													<AggregatesDirective>

														<AggregateDirective>
															<AggregateColumnsDirective>
																<AggregateColumnDirective field='carrier_name' width='120' type='Custom' footerTemplate={() => <span>Total: Carriers</span>} />
																<AggregateColumnDirective field='sales' width='120' type='Sum' footerTemplate={footerSum} />
																<AggregateColumnDirective field='total_amount' width='120' type='Sum' footerTemplate={footerCurrency} />
																<AggregateColumnDirective field='clicks' width='120' type='Sum' footerTemplate={footerSum} />
																<AggregateColumnDirective field='spend' width='120' type='Sum' footerTemplate={footerCurrency} />
																<AggregateColumnDirective field='cpa' width='120' type='Custom' customAggregate={totalCpaFn} footerTemplate={footerCpa} />
																<AggregateColumnDirective field='cvr' width='120' type='Custom' customAggregate={totalCvrFn} footerTemplate={footerCvr} />
																<AggregateColumnDirective field='roas' width='120' type='Custom' customAggregate={totalRoasFn} footerTemplate={footerRoas} />
															</AggregateColumnsDirective>
														</AggregateDirective>

														<AggregateDirective>
															<AggregateColumnsDirective>
																<AggregateColumnDirective field='carrier_name' width='120' type='Custom' footerTemplate={() => <span>Total: Account</span>} />
																<AggregateColumnDirective field='sales' width='120' type='Sum' footerTemplate={footerSum} />
																<AggregateColumnDirective field='total_amount' width='120' type='Sum' footerTemplate={footerCurrency} />
																<AggregateColumnDirective field='clicks' width='120' type='Sum' footerTemplate={accountTotalClicks} />
																<AggregateColumnDirective field='spend' width='120' type='Sum' footerTemplate={accountTotalSpend} />
																<AggregateColumnDirective field='cpa' width='120' type='Custom' customAggregate={accountTotalCpaFn} footerTemplate={footerCpa} />
																<AggregateColumnDirective field='cvr' width='120' type='Custom' customAggregate={accountTotalCvrFn} footerTemplate={footerCvr} />
																<AggregateColumnDirective field='roas' width='120' type='Custom' customAggregate={accountTotalRoasFn} footerTemplate={footerRoas} />
															</AggregateColumnsDirective>
														</AggregateDirective>


													</AggregatesDirective>

													<Inject services={[Freeze, Sort, Aggregate]}/>
												</GridComponent>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
						</Row>

						<Row className="mb-3">
							<Col md={12}>
								<Card>
									<Card.Header>Prior Metrics</Card.Header>

									<Card.Body>
										<Row className="mb-3">
											<Col md={12}>
												<TreeGridComponent dataSource={past_carriers_data} enableAdaptiveUI={true} allowSorting={true} treeColumnIndex={0} childMapping='carriers_breakdowns' enableCollapseAll={true}>
													<ColumnsDirective>
														<ColumnDirective field='carrier_name' width='150' headerText='Carrier' />
														<ColumnDirective field='sales' width='120' headerText='Sales' />
														<ColumnDirective field='total_amount' width='150' headerText='Refill Amount' template={showAmount} />
														<ColumnDirective field='clicks' width='120' headerText='Clicks' />
														<ColumnDirective field='spend' width='120' headerText='Cost' template={showSpend} />
														<ColumnDirective field='cpa' width='120' headerText='CPA' template={showCpa} />
														<ColumnDirective field='cvr' width='120' headerText='Conv. Rate' template={showCvr} />
														<ColumnDirective field='roas' width='120' headerText='ROAS' template={showRoas} />
													</ColumnsDirective>
													<TreeGridInject services={[TreeGridSort]}/>
												</TreeGridComponent>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</>
			}

			{isPageLoading &&
				<DashboardSkeleton />
			}

			<StatCounter sc_project={12578404} sc_security="784cb42e" sc_image_url="https://c.statcounter.com/12578404/0/784cb42e/1/" />
			</Layout>
		</>
	)
}

export default memo(Dashboard)