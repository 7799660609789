import axios from 'axios'

class ReportingService {

	getCarriersReportData(params) {
		return new Promise((resolve, reject) => {
			axios.get(`/api/reporting/carrier_report/`, {
				params,
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

	getCarriersAnalyticsData(params) {
		return new Promise((resolve, reject) => {
			axios.get(`/api/reporting/analytics/`, {
				params,
			}).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

}
const reportingService = new ReportingService()

export default reportingService