import ReportingService from 'services/reportingService'

const getCarriersReportData = (params) => async(dispatch) => {
   try {
      
      const response = await ReportingService.getCarriersReportData(params)

      if(response.data.success) {
         dispatch({
            type: 'UPDATE_REPORT_DATA',
            payload: response.data,
         })
      }
      return response

   } catch(err) {
      console.log(err)
   }  
}

const getCarriersAnalyticsData = (params) => async(dispatch) => {
   try {
      
      const response = await ReportingService.getCarriersAnalyticsData(params)

      if(response.data.success) {
         dispatch({
            type: 'UPDATE_ANALYTICS_DATA',
            payload: response.data,
         })
      }
      return response

   } catch(err) {
      console.log(err)
   }  
}

export {
	getCarriersReportData,
   getCarriersAnalyticsData,
}