import { connect } from 'react-redux'
import Analytics from 'pages/carriers/analytics'
import { getCarriersAnalyticsData } from 'actions'

const mapStateToProps = (state) => {
   return {
      ...state.analyticsReducer
   }
}

const mapActionsToProps = {
   getCarriersAnalyticsData,
}

export default connect(mapStateToProps, mapActionsToProps)(Analytics)